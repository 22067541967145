import { defineComponent } from 'vue'

import DsView from '@/components/DsView'
import { Model } from '@/components/DsForm'
import { Column } from '@/components/DsTable'
import DsTag from '@/components/DsTag'

import {
  availablePaymentType,
  availablePaymentStatus,
  availableChannelType,
  availableProductSize,
  availableProductColors,
  availableProductHeel,
} from '@/assets/enums'

const orderModel: Model = {
  type: 'object',
  properties: {
    order_number: {
      type: 'string',
      label: 'Client order #',
      required: true,
    },
    client_id: {
      model: 'clients',
      type: 'uuid',
      label: 'Client',
      optionLabel: (client: any) => `${client.name} ${client.lastname}`,
    },
    quantity: {
      type: 'number',
      label: 'Quantity',
    },
    product: {
      type: 'string',
      label: 'Product',
    },
    status: {
      type: 'string',
      enum: [
        { value: 'in_production', label: 'In Production' },
        { value: 'finished', label: 'Finished' },
      ],
      label: 'Status',
      disabled: true,
    },
    size: {
      type: 'string',
      enum: availableProductSize,
      allowCreate: true,
      label: 'Size',
    },
    heel: {
      type: 'string',
      enum: availableProductHeel,
      allowCreate: true,
      label: 'Heel',
    },
    color: {
      type: 'string',
      enum: availableProductColors,
      allowCreate: true,
      label: 'Color',
    },
    payment_type: {
      type: 'string',
      enum: availablePaymentType,
      allowCreate: true,
      label: 'Payment type',
    },
    channel_type: {
      type: 'string',
      enum: availableChannelType,
      allowCreate: true,
      label: 'Channel type',
    },
    payment_status: {
      type: 'string',
      enum: availablePaymentStatus,
      allowCreate: true,
      label: 'Payment status',
    },
    comment: {
      type: 'string',
      label: 'Comment',
    },
  },
}

const columns: Column[] = Object.entries(orderModel.properties).map(
  ([k, value]) => {
    const column: Column = { key: k, header: value.label }
    if (k === 'client_id') {
      column.formatter = (value: any, relations: any) => {
        return value === undefined ||
          !relations.clients ||
          !relations.clients[value]
          ? '-'
          : `${relations.clients[value].name} ${relations.clients[value].lastname}`
      }
    } else if (k === 'product') {
      column.formatter = (value) => <DsTag>{value}</DsTag>
    } else if (k === 'status') {
      column.formatter = (value: any, relations: any) => {
        switch (value) {
          case 'in_production':
            return <DsTag>In Production</DsTag>
          case 'finished':
            return <DsTag>Finished</DsTag>
          default:
            return ''
        }
      }
    }
    return column
  }
)

const defaultOrder = () => ({})

export default defineComponent({
  name: 'Orders',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'orders',
          title: 'Clients Orders',
          model: orderModel,
          columns: columns,
          createButtonLabel: 'New order',
          rowKey: 'id',
        }}
        defaultValue={defaultOrder}
      />
    )
  },
})
